@keyframes waveTranslate {
  0% {
    transform: translateY(-0.5em);
  }
  50% {
    transform: translateY(0.5em);
  }
  100% {
    transform: translateY(-0.5em);
  }
}

@keyframes waveRotate {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-5deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

/* Wavy animated text */
.Wave {
  & * {
    @apply inline-block;
    animation-duration: 4s;
    animation-iteration-count: infinite;
  }
}

.Wave-translate {
  transform: translateY(-0.5em);
  animation-timing-function: ease-in-out;
  animation-name: waveTranslate;
}

.Wave-rotate {
  animation-timing-function: linear;
  animation-name: waveRotate;
}